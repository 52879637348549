import { useTranslation } from 'react-i18next'
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone'
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone'
import TrackChangesTwoToneIcon from '@mui/icons-material/TrackChangesTwoTone'
import OndemandVideoTwoToneIcon from '@mui/icons-material/OndemandVideoTwoTone'
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone'
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone'
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone'
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import CommentIcon from '@mui/icons-material/Comment'
import SendToMobileTwoToneIcon from '@mui/icons-material/SendToMobileTwoTone'
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone'
import LinkIcon from '@mui/icons-material/Link'
import SchoolIcon from '@mui/icons-material/School'
import SubjectIcon from '@mui/icons-material/Subject'
import MissedVideoCallTwoToneIcon from '@mui/icons-material/MissedVideoCallTwoTone'

import { useMemo } from 'react'
import useAuth from './useAuth'

const filterSectionByRole = (sections, role) =>
  sections
    .filter((section) => section.roles.includes(role))
    .map((item) => ({
      ...item,
      items: item.items
        .filter((section) => section.roles.includes(role))
        .map((section) => {
          if (section?.children) {
            return {
              ...section,
              children: section.children.filter((child) => child.roles.includes(role))
            }
          }
          return section
        })
    }))

const useSidebarSectionsByRole = () => {
  const { t } = useTranslation()
  const auth = useAuth()
  const { role } = auth
  const sectionList = useMemo(
    () => [
      {
        title: 'Bài học',
        items: [
          // {
          //   title: 'Lộ trình',
          //   path: '/learning-path',
          //   icon: TodayTwoToneIcon,
          //   roles: ['administrator']
          // },
          {
            title: 'Playlist',
            path: '/course',
            icon: MenuBookTwoToneIcon,
            roles: ['administrator']
          },
          {
            title: 'Video',
            path: '/lesson',
            icon: OndemandVideoTwoToneIcon,
            roles: ['administrator']
          },
          // {
          //   title: 'Lĩnh vực (video)',
          //   path: '/lesson-category',
          //   icon: CategoryTwoToneIcon,
          //   roles: ['administrator']
          // },
          {
            title: 'Danh mục kiến thức',
            path: '/course-category',
            icon: CategoryTwoToneIcon,
            roles: ['administrator']
          }
          // {
          //   title: 'Mục tiêu',
          //   path: '/goal',
          //   icon: TrackChangesTwoToneIcon,
          //   roles: ['administrator']
          // },
          // {
          //   title: 'Phân loại',
          //   path: '/lesson-classify',
          //   icon: CategoryTwoToneIcon,
          //   roles: ['administrator']
          // },
          // {
          //   title: 'Tài liệu',
          //   path: '/course-document',
          //   icon: DescriptionTwoToneIcon,
          //   roles: ['administrator']
          // },
          // {
          //   title: 'Cấp độ',
          //   path: '/course-level',
          //   icon: AddCircleIcon,
          //   roles: ['administrator']
          // },
          // {
          //   title: 'Bình luận video',
          //   path: '/lesson-comment',
          //   icon: CommentIcon,
          //   roles: ['administrator']
          // },
          // {
          //   title: 'Đánh giá video',
          //   path: '/lesson-review',
          //   icon: CommentIcon,
          //   roles: ['administrator']
          // },
          // {
          //   title: 'Đánh giá playlist',
          //   path: '/course-rating',
          //   icon: CommentIcon,
          //   roles: ['administrator']
          // }
        ],
        roles: ['administrator']
      },
      // {
      //   title: 'Hội thảo',
      //   items: [
      //     {
      //       title: 'Lịch hội thảo',
      //       path: '/webinar-scheduler',
      //       icon: TodayTwoToneIcon,
      //       roles: ['administrator', 'author']
      //     },
      //     {
      //       title: 'Duyệt hội thảo',
      //       path: '/webinar-manager',
      //       icon: AdminPanelSettingsIcon,
      //       roles: ['administrator']
      //     },
      //     {
      //       title: 'Đánh giá',
      //       path: '/webinar-review',
      //       icon: CommentIcon,
      //       roles: ['administrator']
      //     },
      //     {
      //       title: 'Báo cáo hội thảo',
      //       path: '/webinar-report',
      //       icon: DescriptionTwoToneIcon,
      //       roles: ['administrator', 'author']
      //     },
      //     {
      //       title: 'Tải lên hội thảo',
      //       path: '/webinar-uploader',
      //       icon: MissedVideoCallTwoToneIcon,
      //       roles: ['administrator', 'author']
      //     }
      //   ],
      //   roles: ['administrator', 'author']
      // },
      {
        title: 'Tin tức ',
        items: [
          {
            title: 'Bài viết',
            path: '/news-article',
            icon: ArticleTwoToneIcon,
            roles: ['administrator']
          },
          {
            title: 'Danh mục',
            path: '/news-category',
            icon: CategoryTwoToneIcon,
            roles: ['administrator']
          }
        ],
        roles: ['administrator']
      },
      // {
      //   title: 'Blog',
      //   items: [
      //     {
      //       title: 'Bài viết',
      //       path: '/blog',
      //       icon: ArticleTwoToneIcon,
      //       roles: ['administrator']
      //     },
      //     {
      //       title: 'Danh mục',
      //       path: '/blog-category',
      //       icon: CategoryTwoToneIcon,
      //       roles: ['administrator']
      //     }
      //   ],
      //   roles: ['administrator']
      // },
      {
        title: 'Sàn ',
        items: [
          {
            title: 'Sàn',
            path: '/exchange',
            icon: CurrencyExchangeIcon,
            roles: ['administrator']
          },
          {
            title: 'Đánh giá sàn',
            path: '/exchange-review',
            icon: CommentIcon,
            roles: ['administrator']
          }
        ],
        roles: ['administrator']
      },
      {
        title: 'Insight',
        items: [
          {
            title: 'Tạp chí',
            path: '/magazine',
            icon: BackupTableTwoToneIcon,
            roles: ['administrator']
          },
          {
            title: 'Bài viết',
            path: '/insight',
            icon: ArticleTwoToneIcon,
            roles: ['administrator']
          }
          // {
          //   title: 'Đánh giá',
          //   path: '/magazine-review',
          //   icon: CommentIcon,
          //   roles: ['administrator']
          // },
          // {
          //   title: 'Bình luận',
          //   path: '/magazine-comment',
          //   icon: CommentIcon,
          //   roles: ['administrator']
          // }
        ],
        roles: ['administrator']
      },

      {
        title: 'Kiến thức',
        items: [
          {
            title: 'Bài viết',
            path: '/knowledge',
            icon: ArticleTwoToneIcon,
            roles: ['administrator']
          },
          {
            title: 'Series',
            path: '/series',
            icon: SchoolIcon,
            roles: ['administrator']
          }
        ],
        roles: ['administrator']
      },
      {
        title: 'Đường dẫn',
        items: [
          {
            title: 'Quản lý đường dẫn',
            path: '/redirect',
            icon: LinkIcon,
            roles: ['administrator']
          }
        ],
        roles: ['administrator']
      },
      {
        title: 'Hệ thống',
        items: [
          {
            title: 'Quản lý người dùng',
            path: '/user-manager',
            icon: GroupTwoToneIcon,
            roles: ['administrator']
          },
          {
            title: 'Quản lý seeder',
            path: '/seeder',
            icon: AssignmentIndTwoToneIcon,
            roles: ['administrator']
          },
          {
            title: 'Quản lý quảng cáo',
            path: '/ads-manager',
            icon: CampaignTwoToneIcon,
            roles: ['administrator']
          },
          {
            title: 'Quản lý liên hệ',
            path: '/contact',
            icon: CampaignTwoToneIcon,
            roles: ['administrator']
          },
          {
            title: 'Quản lý thông báo',
            path: '/notification-manager',
            icon: SendToMobileTwoToneIcon,
            roles: ['administrator']
          }
        ],
        roles: ['administrator']
      }
    ],
    [t]
  )

  return filterSectionByRole(sectionList, role)
}

export default useSidebarSectionsByRole
